import React, { useState } from 'react';
// Packages
import axios from 'axios';
import Swal from 'sweetalert2';
// components
import CardsCompoOne from '../CardsCompoOne';
import CardsCompoTwo from '../CardsCompoTwo';
import CardsCompoThree from '../CardsCompoThree';
import CardsCompoFour from '../CardsCompoFour';
import CardsCompoFive from '../CardsCompoFive';

function Index() {
  // Open targated component hook
  const [openComponent, setopenComponent] = useState(1);
  // Open targated component hook

  // interface stateData{
  //   setProduct?:string,
  //   setPropertyType?:string,
  //   setCost?:number,
  // }

  // states to POST data
  const [product, setProduct] = useState<string>('');
  const [propertyType, setPropertyType] = useState<string>('');
  const [cost, setCost] = useState<string>('25000');
  const [name, setName] = useState<string>('');
  const [contact, setContact] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [address, setinpAddress] = useState<string>('');
  const [place, setPlace] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [state, setState] = useState<string>('');
  const [zipCode, setzipCode] = useState<string>('');
  const [home_value, sethomeCost] = useState<string>('200000');
  // Unit number******
  const [unitNumber, setUnitNumber] = useState<any>('');

  // *********
  console.log(cost);
  console.log(home_value);
  console.log(propertyType);
  // let theAdd= unitNumber===""? place: `${unitNumber}, ${place}`;
  let theAdd = `${unitNumber} ${place}`;

  // *********

  const formatCurrency = (home_value: string) => {
    return (
      '$' +
      parseFloat(home_value)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
    );
  };

  // *********
  function phoneFormat(n: any) {
    //returns (###) ###-####
    let input = n.slice(2);
    // let input = n
    // input = input.replace(/\D/g, '')
    // console.log(input)
    var size = input.length;
    if (size > 0) {
      input = '(' + input;
    }
    if (size > 3) {
      input = input.slice(0, 4) + ') ' + input.slice(4, 11);
    }
    if (size > 6) {
      input = input.slice(0, 9) + '-' + input.slice(9);
    }
    return input;
    // console.log(input)
  }
  // *********
  const convertCost = () => {
    if (cost == '900001') {
      setCost('1000000');
    }
  };
  // *********

  let formattedCostValue = formatCurrency(cost);
  let formattedHomeValue = formatCurrency(home_value);

  const handleLandingPageData = async () => {
    convertCost();

    // *****************Phone number valid or note API*********************

    console.log(product);
    console.log(propertyType);

    // console.log(formattedHomeValue)
    // console.log(formattedCostValue)
    console.log(name);
    console.log(phoneFormat(contact));
    console.log(`$${home_value}`);
    console.log(`$${cost}`);
    console.log(email);
    console.log(place);
    console.log(theAdd);
    console.log(city);
    console.log(state);
    console.log(zipCode);

    // *************Build formData object.***************

    // --------------------------------------
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Your details has been saved',
      customClass: {
        popup: 'page1_popup'
      },
      inputAttributes: {
        id: 'page1_popup'
      },
      allowOutsideClick: false,
      // showConfirmButton: false,
      // timer: 2500,

      showCancelButton: false,
      width: 400,
      confirmButtonColor: '#7059a7',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href = 'https://nuborrow.com/';
        return null;
      }
    });
    // --------------------------------------
    let formData = new FormData();
    formData.append('place', theAdd);
    // formData.append("product_id", "4")
    formData.append('name', name);
    formData.append('email', email);
    formData.append('phone', phoneFormat(contact));
    formData.append('product', product);
    formData.append('city', city);
    formData.append('state', state);
    formData.append('zipCode', zipCode);
    formData.append('money_need', `${formattedCostValue}`);
    formData.append('home_value', `${formattedHomeValue}`);
    formData.append('propertyType', propertyType);
    formData.append('lead_source', '24');

    // // *********************API starts**********************

    // https://corsproxyapi.herokuapp.com/

    try {
      const data = await axios({
        method: 'post',
        url: 'https://nu2morrow.com/crm/apis/lead/add_landingpage',
        // url: 'https://dev.nu2morrow.com/crm/apis/lead/add_landingpage_dev',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      console.log(data);
    } catch (err: any) {
      console.log(err.message);
      alert(err.message);
    }
    console.log(formData);

    // *************************API ends*************************

    console.log(`$${home_value}`);
    console.log(`$ ${cost}`);

    setProduct('');
    setPropertyType('');
    setCost('');
    setName('');
    setContact('');
    setEmail('');
    setinpAddress('');
    setPlace('');
    setCity('');
    setState('');
    setzipCode('');
    sethomeCost('');

    // window.location.href = 'https://nuborrow.com/'; //Will take you to Nuborrow Web App.
  };

  return (
    <>
      {/* ************************Header cards************************* */}
      <section id="view" className="w-full">
        {/* <div className="flex flex-wrap sm:w-[90%] md:h-[50%] sm:mx-auto m-auto px-5 py-5"> */}
        {/* <div className="xl:w-1/2 md:pr-12 md:py-8 w-[100%] mx-auto mb-10 md:mb-0 pb-10"> */}
        {/* <PageOneComponent /> */}
        {/* <PageTwoComponent /> */}
        {/* <PageThreeComponent /> */}
        {/* <PageFourComponent /> */}
        {/* <PageFiveComponent /> */}

        {/* ******************************* */}
        {openComponent === 1 ? (
          <CardsCompoOne setopenComponent={setopenComponent} setProduct={setProduct} />
        ) : openComponent === 2 ? (
          <CardsCompoTwo setopenComponent={setopenComponent} setPropertyType={setPropertyType} />
        ) : openComponent === 3 ? (
          <CardsCompoThree
            setopenComponent={setopenComponent}
            sethomeCost={sethomeCost}
            home_value={home_value}
            formatCurrency={formatCurrency}
          />
        ) : openComponent === 4 ? (
          <CardsCompoFour
            setopenComponent={setopenComponent}
            setCost={setCost}
            cost={cost}
            formatCurrency={formatCurrency}
          />
        ) : (
          <CardsCompoFive
            setName={setName}
            setContact={setContact}
            setEmail={setEmail}
            setinpAddress={setinpAddress}
            handleLandingPageData={handleLandingPageData}
            setPlace={setPlace}
            setCity={setCity}
            setState={setState}
            setzipCode={setzipCode}
            propertyType={propertyType}
            unitNumber={unitNumber}
            setUnitNumber={setUnitNumber}
            setopenComponent={setopenComponent}
            name={name}
            contact={contact}
            email={email}
            place={place}
          />
        )}
        {/* ******************************* */}
        {/* </div> */}
        {/* </div> */}
      </section>
    </>
  );
}

export default Index;
